import { DocumentService } from "@app/types-business/documents";
import { Api, ApiResponse } from "../_base";
import moment from "moment-timezone";
import queryString from "query-string";
import { DocumentRelation } from "@app/entities/document";

export * from "./review";
export * from "./insights";
export * from "../lsr";

/**
 * Create a document
 * @param request
 */
export function createDocument(
  request: DocumentService.CreateDocumentRequest
): Promise<ApiResponse<DocumentService.Document>> {
  return Api.execute({
    url: "/api/documents",
    data: request,
    method: "POST",
  });
}

export function createReviewDocument(
  request: DocumentService.CreateDocumentRequest
): Promise<ApiResponse<DocumentService.Document>> {
  return Api.execute({
    url: "/api/review/documents",
    data: request,
    method: "POST",
  });
}

export function createFiledExecutedDocument(
  request: DocumentService.CreateDocumentRequest
): Promise<ApiResponse<DocumentService.Document>> {
  return Api.execute({
    url: "/api/documents/file-executed",
    data: request,
    method: "POST",
  });
}

/**
 * Create a document
 * @param request
 */
export const deleteDocument = (documentId: string) => {
  return Api.execute({
    url: `/api/proxy/api-documents/v2/documents/${documentId}`,
    method: "DELETE",
  });
};

/**
 * Get a document
 * @param documentId
 */
export function getDocument(
  documentId: string
): Promise<ApiResponse<DocumentService.Document>> {
  return Api.execute({
    url: `/api/documents/${documentId}`,
  });
}

/**
 * Add documentVersion
 * @param request
 */
export function addDocumentVersion(
  request: DocumentService.AddDocumentVersionRequest
): Promise<ApiResponse<DocumentService.Document>> {
  const { documentId, ...data } = request;
  return Api.execute({
    url: `/api/proxy/api-documents/v2/documents/${documentId}/add-version`,
    data,
    method: "POST",
  });
}

export function changeCounterparty(
  documentId: string,
  data: { counterpartyId: string } | { counterpartyName: string }
): Promise<any> {
  return Api.execute({
    url: `/api/documents/${documentId}/change-counterparty`,
    data,
    method: "PATCH",
  });
}

export function moveDocument(
  documentId: string,
  counterpartyId: string,
  folderId: string,
  currentFolderId: string
): Promise<any> {
  return Api.execute({
    url: `/api/documents/${documentId}/move-document`,
    data: {
      counterpartyId,
      folderId,
      currentFolderId,
    },
    method: "POST",
  });
}

/**
 * Returns the list of versions associated with the document
 * @param documentId The document Id whose versions are to be fetched
 */
export function getDocumentVersions(
  documentId: number | string
): Promise<ApiResponse<DocumentService.DocumentVersionsResponse>> {
  return Api.execute({
    url: `/api/documents/${documentId}/versions`,
  });
}

export function assignDocument(
  documentId: string,
  userId: string,
  message?: string
) {
  return Api.execute({
    url: `/api/proxy/api-documents/v2/documents/${documentId}/assign`,
    method: "POST",
    data: { userId, message },
  });
}

export function download(
  documentId: string,
  versionNumber?: number
): Promise<any> {
  return Api.execute({
    url: `/api/documents/${documentId}/download`,
    params: {
      versionNumber,
    },
  });
}

export function downloadFile(fileId: string): Promise<any> {
  return Api.execute({
    url: `/api/proxy/ms-storage/v1/storage/${fileId}/download`,
  });
}

export function exportDocument(
  documentId: string,
  versionNumber: number
): Promise<any> {
  return Api.execute({
    url: `/api/proxy/api-documents/v2/documents/${documentId}/export`,
    params: {
      versionNumber,
    },
  });
}

export const getDocumentList = (query: Record<string, any> = {}) => {
  query.tz = moment().utcOffset();
  return Api.execute({
    url: `/api/documents`,
    params: query,
  });
};

export function loadQuickView(
  documentId: string,
  params?: { versionNumber: number }
): Promise<any> {
  return Api.execute({
    url: `/api/documents/${documentId}/quick-view`,
    params,
  });
}

export function updateDocument(
  documentId: string,
  data: any
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/documents/${documentId}`,
    data,
    method: "PATCH",
  });
}

export function addRelatedDocument(
  documentId: string,
  relatedDocuments: DocumentRelation[]
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/documents/${documentId}/add-related`,
    data: {
      relatedDocuments,
    },
    method: "POST",
  });
}

export function removeRelatedDocument(
  documentId: string,
  relatedDocuments: DocumentRelation[]
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/documents/${documentId}/remove-related`,
    data: {
      relatedDocuments,
    },
    method: "POST",
  });
}

export interface SearchExportRequest {
  objectType: string;
  format: "csv";
  fields: string[];
  templates?: Record<string, string>;
  headers?: Record<string, string>;
  search?: string;
  criteria?: string;
  orderBy?: string;
  tz?: number;
}

export function exportSearchAsCSV(exportPayload: SearchExportRequest) {
  return Api.execute({
    url: `/api/proxy/ms-search/v1/search/export`,
    data: exportPayload,
    method: "POST",
  });
}

/**
 * Loads the history information for a given document
 * @param documentId
 */
export function getDocumentHistory(
  documentId: string,
  query: Record<string, any> = {}
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/documents/${documentId}/history?${queryString.stringify(query)}`,
  });
}

/**
 * Loads the metrics information for a given document
 * @param documentId
 */
export function getDocumentMetrics(
  documentId: string
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/documents/${documentId}/metrics`,
  });
}

/**
 * Reprocess document
 * @param documentId
 * @param playbookId
 * @param companyId
 * @returns
 */
export function reprocessDocument(
  data: DocumentService.ReprocessDocumentRequest
) {
  const { companyId, documentId, ...rest } = data;
  return Api.execute({
    url: `/api/proxy/api-documents/v2/documents/${documentId}/reprocess`,
    data: {
      ...rest,
    },
    params: {
      companyId,
    },
    method: "POST",
  });
}

export function updateAccess(
  documentId: string,
  data: DocumentService.UpdateAccessRequest
) {
  return Api.execute({
    url: `/api/documents/${documentId}/update-access`,
    method: "POST",
    data,
  });
}

export function revertDocument(
  documentId: string,
  options: {
    auditId?: number;
    previousVersion?: boolean;
    lastProcessing?: boolean;
  } = { previousVersion: true }
) {
  return Api.execute({
    url: `/api/proxy/api-documents/v2/documents/${documentId}/rollback`,
    method: "POST",
    data: options,
  });
}
