import { formatDate, FormatDateOptions } from "@app/utils/date-time";
import {
  compact,
  get,
  groupBy,
  isEmpty,
  isNil,
  isObjectLike,
  keyBy,
  omit,
  orderBy,
  xor,
} from "@app/utils/lodash";
import replace from "lodash/replace";
import { useMemo } from "react";
import { Playbook } from "../playbook";

export enum MetadataFieldType {
  Keyword = "keyword",
  Number = "number",
  Date = "date",
  Text = "text",
  Boolean = "boolean",
  User = "user",
}

export enum MetadataFieldObjectType {
  Document = "DOCUMENT",
  Ticket = "TICKET",
  Counterparty = "COUNTERPARTY",
}

export interface MetadataField {
  companyId?: number;
  counterparty: boolean;
  createdBy?: number;
  createdDate?: string;
  description: string;
  displayName: string;
  filing: boolean;
  groupName?: string;
  id?: number;
  name: string;
  options?: { option: string; count: number }[]; // aggregation options...these are merged into the metadata field for search filters
  picklist?: string[];
  playbookDefault: boolean;
  position: number;
  search: boolean;
  system: boolean;
  type: MetadataFieldType | "TEXT" | "DATE" | "KEYWORD" | "NUMBER"; // in most cases, the value is the normalized lower-case type (seen in enum) but native BE type is upper case
  updatedBy?: number;
  updatedDate?: string;
  useForTitle: boolean;
  multiSelection?: boolean;
  lock?: boolean;
  ticket?: boolean;
  document?: boolean;
  objectType: MetadataFieldObjectType;
}

export interface PlaybookMetadataField
  extends Omit<
    MetadataField,
    "playbookDefault" | "search" | "type" | "objectType"
  > {
  dataType: string;
  playbookId: number;
  metadataId: number;
  replacement: boolean;
  required: boolean;
  library: boolean;
  type?: MetadataFieldType | "TEXT" | "DATE" | "KEYWORD" | "NUMBER"; // BFF normalizes this property so it's in all playbook metadata, but it is not on the native BE playbook metadata obj.
  playbook?: Playbook;
}

export type MetadataValueKeyword = string | string[];
export type MetadataValueDate = number | string; // epoch and iso strings supported
export type MetadataValueNumber = number;
export type MetadataValueBoolean = boolean;
export type MetadataValue =
  | MetadataValueKeyword
  | MetadataValueDate
  | MetadataValueNumber
  | MetadataValueBoolean;

export type MetadataValueOptions = FormatDateOptions & { emptyValue?: string };

export interface CollapsibleMetadataField {
  groupName: string;
  fields: MetadataField[];
  isCollapsible: boolean;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
}

export const isLockedField = (field: MetadataField | PlaybookMetadataField) =>
  get(field, "lock") || false;

export const isSystemField = (field: MetadataField | PlaybookMetadataField) =>
  get(field, "system") || false;

export const isNonSystemField = (
  field: MetadataField | PlaybookMetadataField
) => !isSystemField(field);

export const isUsedInSearch = (field: MetadataField) =>
  !!get(field, "search", false);

export const getFieldType = (
  field: MetadataField | PlaybookMetadataField
): MetadataFieldType =>
  (
    get(field, "type") ||
    get(field, "dataType") ||
    ""
  ).toLowerCase() as MetadataFieldType;

export const getFieldObjectType = (
  field: MetadataField | PlaybookMetadataField
): MetadataFieldObjectType =>
  (get(field, "objectType") || "") as MetadataFieldObjectType;

export const isDocumentObjectTypeField = (field: MetadataField) =>
  getFieldObjectType(field) === MetadataFieldObjectType.Document;

export const isKeywordField = (field: MetadataField | PlaybookMetadataField) =>
  getFieldType(field) === MetadataFieldType.Keyword;

export const isDocumentField = (field: MetadataField | PlaybookMetadataField) =>
  get(field, "document", false);

export const isTicketField = (field: MetadataField | PlaybookMetadataField) =>
  get(field, "ticket", false);

export const isDateField = (field: MetadataField | PlaybookMetadataField) =>
  getFieldType(field) === MetadataFieldType.Date;

export const isSearchField = (field: MetadataField | PlaybookMetadataField) =>
  get(field, "search", false);

export const isAbsoluteDateField = (
  field: MetadataField | PlaybookMetadataField
) =>
  isDateField(field) &&
  ![
    "ticket.createdDate",
    "ticket.updatedDate",
    "document.createdDate",
    "document.updatedDate",
  ].includes(field.name); // exclude system dates `createdDate` and `updatedDate` since they are never absolute.

export const isNumberField = (field: MetadataField | PlaybookMetadataField) =>
  getFieldType(field) === MetadataFieldType.Number;

export const isTextField = (field: MetadataField | PlaybookMetadataField) =>
  getFieldType(field) === MetadataFieldType.Text;

export const isBooleanField = (field: MetadataField | PlaybookMetadataField) =>
  getFieldType(field) === MetadataFieldType.Boolean;

export const hasPicklist = (field: MetadataField | PlaybookMetadataField) =>
  !isEmpty(get(field, "picklist"));

export const isCounterpartyField = (
  field: MetadataField | PlaybookMetadataField
) => field.counterparty;

export const isReplacementField = (field: PlaybookMetadataField) =>
  field.replacement;

export const getMetadataId = (field: MetadataField | PlaybookMetadataField) =>
  get(field, "id") || null;

/**
 * Utility function that formats (for display) a value for a given metadata field.
 */
export const getMetadataDisplayValue = (
  field: MetadataField | PlaybookMetadataField,
  value: MetadataValue,
  options: MetadataValueOptions = {},
  t = (s: string, ...a) => s
): string => {
  const { emptyValue = "", ...dateOptions } = options;

  if (isNil(value) || value === emptyValue) {
    return emptyValue;
  }

  let metadataValue: any = value;

  if (isKeywordField(field) && Array.isArray(value)) {
    return value.join("; ");
  }

  if (isObjectLike(value)) {
    return emptyValue;
  }

  if (isBooleanField(field)) {
    metadataValue = t(`filters.${value}-value`, {}, value);
  }

  if (isDateField(field)) {
    const isAbsoluteDate = !["createdDate", "updatedDate"].includes(field.name);
    const dateOpts = {
      showTime: !isAbsoluteDate,
      isAbsolute: isAbsoluteDate,
      ...dateOptions,
    };
    metadataValue = formatDate(metadataValue as string | number, dateOpts);
  }

  return metadataValue;
};

export const getMetadataDisplayName = (
  field: MetadataField | PlaybookMetadataField,
  defaultName?: string
) =>
  get(field, "displayName") ||
  replace(defaultName ?? get(field, "name", ""), "_", " ");

/**
 * Given an array of ids or names (or another unique property), resolves to an
 * array of the full metadata or playbook-metadata field objects
 * @param propertyValues An array of values (e.g., metadata ids or names)
 * @param metadataFields An array of metadata field objects
 * @param propertyName The name of the property used (e.g., `id` or `name`)
 * @returns An array of fully resolved metadata field objects
 */
export const resolveMetadataFields = (
  propertyValues: any[] = [],
  metadataFields: MetadataField[],
  propertyName: string = "id"
): MetadataField[] => {
  const metadataMap: { [key: string]: MetadataField } = keyBy(
    metadataFields,
    propertyName
  );
  return compact(
    propertyValues.map((propertyValue) => metadataMap[propertyValue])
  );
};

export const getAggregationParameters = (metadata: MetadataField[]): string => {
  const keywordMetadata = (metadata || []).filter(
    (m) => m.type === MetadataFieldType.Keyword
  );
  return keywordMetadata.map((m) => m.name).join(",");
};

export const useCollapsibleMetadataFields = (
  metadata: MetadataField[],
  collapsedGroups: string[],
  updateCollapsedGroups: (groups: string[]) => void
): any => {
  const groupedFields = useMemo(() => {
    const noGroupName = "no-group";

    // grouping the filters by groupName
    const metadataGroups: any = groupBy(
      metadata,
      (item) => get(item, "groupName") || noGroupName
    );

    const groups: CollapsibleMetadataField[] = [
      {
        groupName: noGroupName,
        fields: [...(metadataGroups[noGroupName] || [])],
        isCollapsible: false,
      },
    ];

    Object.keys(omit(metadataGroups, noGroupName)).forEach((key) => {
      groups.push({
        groupName: key,
        fields: orderBy(metadataGroups[key] || [], ["position"], ["asc"]),
        isCollapsible: true,
        isCollapsed: collapsedGroups.includes(key),
        toggleCollapse: () =>
          updateCollapsedGroups(xor(collapsedGroups, [key])),
      });
    });

    return groups;
  }, [metadata, collapsedGroups, updateCollapsedGroups]);

  return groupedFields;
};
