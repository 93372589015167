import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { get, filter } from "@app/utils/lodash";
import { createSelector } from "reselect";
import IAction from "@app/types/IAction";
import * as API from "@app/API";
import { CreatePlaybookMetadataRequest } from "@app/types-business/Insights";
import { PlaybookMetadataField } from "@app/entities/metadata";
import { NOOP } from "@app/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  DataStatus,
  hasDataError,
  isDataLoading,
  isDataNotLoaded,
  runSelector,
  hasDataLoaded,
  isDataSubmitting,
} from "@app/redux/utils";
import { UPDATE_METADATA_ENTRY } from "./company-metadata";

//#region TYPES
export const ADD_PLAYBOOK_METADATA = "PLAYBOOK_METADATA::ADD";
export const UPDATE_PLAYBOOK_METADATA = "PLAYBOOK_METADATA::UPDATE";
export const DELETE_PLAYBOOK_METADATA = "PLAYBOOK_METADATA::DELETE";
export const LOAD_PLAYBOOK_METADATA = "PLAYBOOK_METADATA::LOAD";
export const RESET_PLAYBOOK_METADATA = "PLAYBOOK_METADATA::RESET";
// todo: reset insights admin action - check to make sure we're not losing anythign, since it's not here
//#endregion

//#region ACTIONS
export const loadPlaybookMetadata = (playbookId: number): IAction => ({
  type: LOAD_PLAYBOOK_METADATA,
  promise: API.loadPlaybookMetadata(playbookId),
  meta: {
    playbookId,
  },
});

export const resetPlaybookMetadata = () => {
  return {
    type: RESET_PLAYBOOK_METADATA,
  };
};

export const createPlaybookMetadata = (
  playbookId: number,
  metadataId: number,
  request: CreatePlaybookMetadataRequest,
  onSuccess: () => void,
  onFailure: () => void
) => ({
  type: ADD_PLAYBOOK_METADATA,
  promise: API.addPlaybookMetadata(playbookId, metadataId, request),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updatePlaybookMetadata = (
  metadataId: number,
  update: Partial<PlaybookMetadataField>,
  onSuccess: () => void,
  onFailure: () => void
) => ({
  type: UPDATE_PLAYBOOK_METADATA,
  promise: API.updatePlaybookMetadata(metadataId, update),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const deleteMetadata =
  (metadataId: number, onSuccess = NOOP, onFailure = NOOP): any =>
  (dispatch) => {
    return dispatch({
      type: DELETE_PLAYBOOK_METADATA,
      promise: API.deletePlaybookMetadata(metadataId),
      meta: {
        metadataId,
        onSuccess,
        onFailure,
      },
    });
  };
//#endregion

//#region SELECTORS
export const playbookMetadataState = (state) => state.data.metadata.playbook;

export const getPlaybookMetadata = createSelector(
  playbookMetadataState,
  (state) => get(state, "playbookMetadata") || []
);

export const getLibraryMetadata = createSelector(
  getPlaybookMetadata,
  (playbookMetadata) => playbookMetadata.filter((meta) => meta.library === true)
);

export const getPlaybookMetadataStatus = createSelector(
  playbookMetadataState,
  (state) => state.playbookMetadataStatus
);

export const isPlaybookMetadataLoading = createSelector(
  getPlaybookMetadataStatus,
  (status) => status === DataStatus.Loading
);

export const isPlaybookMetadataSubmitting = createSelector(
  getPlaybookMetadataStatus,
  (status) => status === DataStatus.Submitting
);

export const isPlaybookMetadataDeleting = createSelector(
  getPlaybookMetadataStatus,
  (status) => status === "deleting"
);

export const hasPlaybookMetadataError = createSelector(
  getPlaybookMetadataStatus,
  (status) => status === DataStatus.Error
);

export const getActivePlaybookId = createSelector(
  playbookMetadataState,
  (state) => state.key
);
//#endregion

//#region  HOOKS
export const usePlaybookMetadata = (playbookId: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status = runSelector(getPlaybookMetadataStatus);
    const key = runSelector(getActivePlaybookId);
    if (!!playbookId && (isDataNotLoaded(status) || key !== playbookId)) {
      dispatch(loadPlaybookMetadata(+playbookId));
    }
  }, [playbookId, dispatch]);

  const metadata = useSelector(getPlaybookMetadata);
  const status = useSelector(getPlaybookMetadataStatus);
  const isLoading = isDataLoading(status);
  const hasError = hasDataError(status);
  const hasLoaded = hasDataLoaded(status);
  const isSubmitting = isDataSubmitting(status);

  const actions = {
    load: loadPlaybookMetadata,
    create: createPlaybookMetadata,
    delete: deleteMetadata,
    update: updatePlaybookMetadata,
  };

  return [metadata, { isLoading, hasError, hasLoaded, isSubmitting }, actions];
};
//#endregion

//#region REDUCER
export interface PlaybookMetadataReduxState {
  playbookMetadataStatus: string;
  playbookMetadata: any[];
  key: number;
}

export const initialState: PlaybookMetadataReduxState = {
  // playbook metadata status
  playbookMetadataStatus: DataStatus.NotLoaded,
  // list of playbook metadata
  playbookMetadata: [],
  // the resource id of the current playbook (playbookId)
  key: null,
};

export default handleActions(
  {
    [LOAD_PLAYBOOK_METADATA]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          playbookMetadataStatus: DataStatus.Loading,
          playbookMetadata: [],
        }),
        failure: (s) => ({
          ...s,
          playbookMetadataStatus: DataStatus.Error,
        }),
        success: (s) => {
          return {
            ...s,
            key: get(action, "meta.playbookId", null),
            playbookMetadata: get(action, "payload.data", []),
            playbookMetadataStatus: DataStatus.Done,
          };
        },
      });
    },
    [ADD_PLAYBOOK_METADATA]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          playbookMetadataStatus: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          playbookMetadataStatus: DataStatus.Error,
        }),
        success: (s) => {
          const newMetadata = get(action, "payload.data", {});
          return {
            ...s,
            playbookMetadata: [...s.playbookMetadata, newMetadata],
            playbookMetadataStatus: DataStatus.Done,
          };
        },
      });
    },
    [UPDATE_PLAYBOOK_METADATA]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          playbookMetadataStatus: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          playbookMetadataStatus: DataStatus.Error,
        }),
        success: (s) => {
          const updatedMetadata: any = get(action, "payload.data", {});
          const playbookMetadata = (s.playbookMetadata || []).map((metadata) =>
            metadata.id === updatedMetadata.id
              ? { ...updatedMetadata }
              : metadata
          );
          return {
            ...s,
            playbookMetadata,
            playbookMetadataStatus: DataStatus.Done,
          };
        },
      });
    },
    [DELETE_PLAYBOOK_METADATA]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          playbookMetadataStatus: "deleting",
        }),
        failure: (s) => ({
          ...s,
          playbookMetadataStatus: DataStatus.Done,
        }),
        success: (s) => {
          const playbookMetadata = filter(
            state.playbookMetadata || [],
            (metadata) => metadata.id !== get(action, "meta.metadataId", null)
          );
          return {
            ...s,
            playbookMetadata,
            playbookMetadataStatus: DataStatus.Done,
          };
        },
      });
    },
    [RESET_PLAYBOOK_METADATA]: () => ({ ...initialState }),
    [UPDATE_METADATA_ENTRY]: (state, action) => {
      // Reset the key whenever the company metadata is updated so that we reload the
      // playbook metadata when the next usePlaybookMetadata() hook is used. This ensures
      // that the changes made to the company metadata are reflected in the playbook metadata.
      return handle(state, action, {
        success: (s) => ({
          ...s,
          key: null,
        }),
      });
    },
  },
  initialState
);
//#endregion
