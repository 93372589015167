import constants from "@app/utils/constants";
import { get, isEmpty, isEqual, isNil, find } from "@app/utils/lodash";
import { getUserId, User } from "../users";
import mime from "mime-types";
import { DocumentService, FileInfo } from "@app/types-business/documents";
import { IntegrationName } from "../integrations";
import { SiftStatus } from "../legalsifter-review";

const { INITIAL, COMPLETE, PROCESSING, REPROCESSING, PENDING } =
  constants.PROCCESS_STATUS_MAP;

const { AWAITING_APPROVAL } = constants.RISK_STATUS_MAP;

const { MIME_TYPES_MAP } = constants;
const { PDF, DOCX } = MIME_TYPES_MAP;

export interface Document extends DocumentService.Document {}

// Document Stats provided by insights-client documents listing
export interface DocumentStats {
  FOR_SIGNATURE?: number;
  IN_REVIEW?: number;
  EXECUTED?: number;
  WITH_COUNTERPARTY?: number;
  AWAITING_SIGNATURE?: number;
}

// TODO: Remove the map in constants and use the enum across the application
export enum SourceType {
  Internal = "FPP",
  External = "TPP",
  Insights = "INS",
  Search = "SRH",
  Filing = "FILING",
  Review = "REVIEW", // used during upload to specify that the review contract's source type should be automatically identified by the system as TPP or FPP
}

export type PaperType = SourceType.Internal | SourceType.External;

export enum ContractStatus {
  Unspecified = "CONTRACT_STATUS_UNSPECIFIED",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum DocumentStatus {
  Unspecified = "STATUS_UNSPECIFIED",
  InReview = "IN_REVIEW",
  WithCounterparty = "WITH_COUNTERPARTY",
  ReadyForSignature = "FOR_SIGNATURE",
  AwaitingSignature = "AWAITING_SIGNATURE",
  Executed = "EXECUTED",
  Canceled = "CANCELED",
}

export enum NegotiationAction {
  Reopen = "reopen",
  CancelNegotiation = "cancel",
  CancelSignature = "cancel-signature",
  SetWithUser = "set-with-user",
  SendForSignature = "send-for-signature",
  SetWithCounterparty = "set-with-counterparty",
  Finalize = "finalize", // used for TPP documents
}

export enum NegotiationStatus {
  TemplateCreated = "TEMPLATE_CREATED",
  InReview = "IN_REVIEW",
  WithCounterparty = "WITH_COUNTERPARTY",
  ReadyForSignature = "FOR_SIGNATURE",
  AwaitingSignature = "AWAITING_SIGNATURE",
  Executed = "EXECUTED",
  Canceled = "CANCELED",
  Filed = "FILED",
  Unspecified = "STATUS_UNSPECIFIED",
  AwaitingApproval = "AWAITING_APPROVAL",
}

export enum ProcessStatus {
  Unspecified = "PROCESS_STATUS_UNSPECIFIED",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Processed = "PROCESSED",
  Failed = "FAILED",
}

export enum TurnStatus {
  Unspecified = "STATUS_UNSPECIFIED",
  Open = "OPEN",
  Done = "DONE",
}

export enum ReviewStatus {
  Unspecified = "STATUS_UNSPECIFIED",
  Open = "OPEN",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Approved = "APPROVED",
  Denied = "DENIED",
  Unchanged = "UNCHANGED",
  AwaitingApproval = "AWAITING_APPROVAL",
  Revised = "REVISED",
}

export enum ExecuteEventAction {
  // Create a document.
  Create = "create",
  // Add a new version of the document.
  AddNewVersion = "addNewVersion",
  // Finalize the negotiation of the document.
  Finalize = "finalize",
  // Assign the document to the specified user.
  Assign = "assign",
  // Patch the document.
  Patch = "patch",
  // Process an issue.
  ProcessIssue = "processIssue",
  // Cancel the negotiation of the document.
  CancelReview = "cancelReview",
  // Cancel the signature of the document.
  CancelSignature = "cancelSignature",
  // Send the document for signature = 12
  SendForSignature = "sendForSignature",
  // Transition the document to the counterparty.
  SetWithCounterparty = "setWithCounterparty",
  // Transition the document to the given user.
  SetWithUser = "setWithUser",
  // Execute the document.
  Execute = "execute",
  // Reopen the document.
  Reopen = "reopen",
  // Request approval
  RequestApproval = "requestApproval",
  // Request approval from multiple users
  RequestMultipleApprovals = "requestApprovals",
  // Process approval action
  ProcessApproval = "processApproval",
  // Relate document
  RelateDocument = "relateDocument",
  // Process document
  ProcessDocument = "processDocument",
  // Complete document process
  CompleteDocumentProcess = "completeDocumentProcess",
  // Starts the finalize process
  StartFinalizeDocument = "startFinalizeDocument",
  // Finishes the finalize process
  FinishFinalizeDocument = "finishFinalizeDocument",
}

export enum DocumentEvent {
  // Created
  // Event recorded when the a document is created.
  Created = "created",
  // Patched
  // Event recorded when the document is partial updated
  Patched = "patched",
  // Assigned
  // Event recorded when the document is assigned or unassigned
  Assigned = "assigned",
  // Transitioned
  // Event recorded when the document status is changed
  Transitioned = "transitioned",
  // VersionAdded
  // Event recorded when a new version of the document is added
  VersionAdded = "versionAdded",
  // IssueProcessed
  // Event recorded when an issue in the document is processed
  IssueProcessed = "issueProcessed",
  // ProcessStarted
  // Event recorded when the document is being processed
  ProcessStarted = "processStarted",
  // ProcessFinished
  // Event recorded when the document is successfully processed
  ProcessFinished = "processFinished",
  // ProcessFailed
  // Event recorded when the document process failed
  ProcessFailed = "processFailed",
  // ApprovalRequestCanceled
  // Event recorded when an approval request is canceled
  ApprovalRequestCanceled = "approvalRequestCanceled",
  // ApprovalRequested
  // Event recorded when an approval request is created
  ApprovalRequested = "approvalRequested",
  // ApprovalProcessed
  // Event recorded when an approval is processed
  ApprovalProcessed = "approvalProcessed",
  // DocumentRelated
  // Event recorded when a document relation has been updated
  DocumentRelated = "documentRelated",
  // SignatureCreated
  // Event recorded when a signature request is created
  SignatureCreated = "signatureCreated",
  // SignatureUpdated
  // Event recorded when a signature request is updated
  SignatureUpdated = "signatureUpdated",
  // SignatureResent
  // Event recorded when signature is resent
  SignatureResent = "signatureResent",
  // SignatureCanceled
  // Event recorded when a signature request is canceled
  SignatureCanceled = "signatureCanceled",
  // FinalizeDocumentStarted
  // Event recorded to mark when the FINALIZE process have started
  FinalizeDocumentStarted = "finalizeDocumentStarted",
  // FinalizeDocumentFinished
  // Event recorded to mark when the FINALIZE process have finished
  FinalizeDocumentFinished = "finalizeDocumentFinished",
  // PlaybookIdSet
  // Event recorded when a playbook id is set
  PlaybookIdSet = "playbookIdSet",
  // ParentContractTypeSet
  // Event recorded when a parent contract type is set
  ParentContractTypeSet = "parentContractTypeSet",
  // MetadataUpdated
  // Event recorded when the metadata is updated
  MetadataUpdated = "metadataUpdated",
  // Event recorded when the document has been sent to LSR for sifting
  SiftForReviewQueued = "siftForReviewQueued",
  SiftForReviewCreated = "siftForReviewCreated",
  SiftForReviewUpdated = "siftForReviewUpdated",
}

export enum HistoryEventNames {
  Created = "created",
  ProcessStarted = "processStarted",
  ProcessFinished = "processFinished",
  ProcessFailed = "processFailed",
  ApprovalRequested = "approvalRequested",
  ApprovalProcessed = "approvalProcessed",
  ApprovalRequestCanceled = "approvalRequestCanceled",
  IssueProcessed = "issueProcessed",
  Transitioned = "transitioned",
  Patched = "patched",
  Updated = "updated",
  FinalizeDocumentStarted = "finalizeDocumentStarted",
  FinalizeDocumentFinished = "finalizeDocumentFinished",
  Assigned = "assigned",
  VersionAdded = "versionAdded",
  DocumentRelated = "documentRelated",
  SignatureCreated = "signatureCreated",
  SignatureUpdated = "signatureUpdated",
  SignatureResent = "signatureResent",
  SignatureCanceled = "signatureCanceled",
  DocumentDeleted = "documentDeleted",
  ApprovalDenied = "approvalDenied",
  ApprovalApproved = "approvalApproved",
  CounterpartyChanged = "counterpartyChanged",
  DocumentResent = "documentResent",
  SendForSignature = "sendForSignature",
  SendForReview = "setWithCounterparty",
  PlaybookChanged = "playbookChanged",
  AttachmentUpdated = "attachmentUpdated",
  AccessUpdated = "accessUpdated",
  documentImported = "DocumentImported",
  RenewalUpdated = "renewalUpdated",
  OwnershipUpdated = "ownershipUpdated",
  ReprocessDocument = "reprocessDocument",
  SiftForReviewQueued = "siftForReviewQueued",
  SiftForReviewCreated = "siftForReviewCreated",
  SiftForReviewUpdated = "siftForReviewUpdated",
  SiftForReviewCompleted = "siftForReviewCompleted",
  SiftForReviewFailed = "siftForReviewFailed",
}

export interface DocumentHistoryEvent {
  name: HistoryEventNames;
  actionName: HistoryEventNames;
  actionDetails?: any;
  createdBy: User;
  createdDate: string;
  details: any;
}

export enum SignatureStatus {
  Unspecified = "STATUS_UNSPECIFIED",
  Pending = "PENDING",
  Sent = "SENT",
  Delivered = "DELIVERED",
  PendingManualAction = "PENDING_MANUAL_ACTION",
  Signed = "SIGNED",
  AutoResponded = "AUTO_RESPONDED",
  Declined = "DECLINED",
}

export enum EnvelopeLinkType {
  Console = "console",
  Edit = "edit",
  Correct = "correct",
  Compose = "COMPOSE",
  All = "ALL",
}

export enum TermType {
  Fixed = "FIXED",
  FixedWithAutoRenewal = "FIXED_WITH_AUTO_RENEWAL",
  FixedWithOptionToRenew = "FIXED_WITH_OPTION_TO_RENEW",
  Evergreen = "EVERGREEN",
  Other = "OTHER",
  Unsure = "UNSURE",
  Unspecified = "TERM_TYPE_UNSPECIFIED",
}

export enum NoticeRequiredDays {
  AnyTime = "ANY_TIME",
  UponRenewal = "UPON_RENEWAL",
  Seven = "7",
  Ten = "10",
  Fourteen = "14",
  Fifteen = "15",
  Thirty = "30",
  FortyFive = "45",
  Sixty = "60",
  SeventyFive = "75",
  Ninety = "90",
  OneHundredTwenty = "120",
  OneHundredFifty = "150",
  OneHundredEighty = "180",
  TwoHundredSeventy = "270",
  ThreeHundredSixtyFive = "365",
  NA = "N/A",
  Other = "OTHER",
  Silent = "SILENT",
}

export const DefaultReminderDaysBefore = 30;

export enum RenewalTimeMonths {
  Three = "3",
  Six = "6",
  Nine = "9",
  Twelve = "12",
  EightTeen = "18",
  TwentyFour = "24",
  ThirtySix = "36",
  FortyEight = "48",
  Sixty = "60",
  NA = "N/A",
  Other = "OTHER",
  SILENT = "SILENT",
}

export interface RenewalInfo {
  contractStatus?: ContractStatus;
  effectiveDate?: string;
  terminationDate?: string;
  termType?: TermType;
  noticeRequiredDays?: NoticeRequiredDays;
  renewalTimeMonths?: RenewalTimeMonths;
}

export const STANDARD_FIELD_NAMES = [
  "document.signature.finalSignatureDate",
  "document.signature.counterpartySignerName",
];

export const STANDARD_FIELD_MAP = {
  "finalSignatureDate":"document.signature.finalSignatureDate",
  "counterpartySignerName":"document.signature.counterpartySignerName",
};

export const RENEWAL_FIELD_NAMES = [
  "contractStatus",
  "effectiveDate",
  "terminationDate",
  "renewal.termType",
  "renewal.noticeRequiredDays",
  "renewal.renewalTimeMonths",
];

// available viewer types for document content
export enum ViewType {
  HTML = "html",
  PDF = "pdf",
  IMAGE = "image",
  DIFF = "diff",
}

export function isTransitionedEvent(event: DocumentHistoryEvent) {
  return event.name === HistoryEventNames.Transitioned;
}

export function getTransitionedStatus(event: DocumentHistoryEvent) {
  const status = event?.details?.after?.status || event?.details?.after;
  if (typeof status !== "string") return undefined;
  return status;
}

export const getHistoryEventType = (event) => {
  if (event.name === HistoryEventNames.SiftForReviewUpdated) {
    const siftStatus = event.actionDetails.status;
    let eventType = HistoryEventNames.SiftForReviewUpdated;
    if (siftStatus === SiftStatus.Failed) {
      eventType = HistoryEventNames.SiftForReviewFailed;
    }
    if (siftStatus === SiftStatus.Sifted) {
      eventType = HistoryEventNames.SiftForReviewCompleted;
    }
    return eventType;
  }
  if (event.name === HistoryEventNames.ApprovalProcessed) {
    return event.details.status === "DENIED"
      ? HistoryEventNames.ApprovalDenied
      : HistoryEventNames.ApprovalApproved;
  } else if (
    event.name === HistoryEventNames.Patched &&
    event.details.before.counterpartyId
  ) {
    return HistoryEventNames.CounterpartyChanged;
  } else if (
    event.name === HistoryEventNames.Patched &&
    event.details.after.playbookId
  ) {
    return HistoryEventNames.PlaybookChanged;
  } else if (
    event.name === HistoryEventNames.Transitioned &&
    !isEmpty(event.actionDetails)
  ) {
    return event.actionName === HistoryEventNames.SendForReview
      ? event.actionName
      : event.name;
  } else if (
    event.actionName === HistoryEventNames.ReprocessDocument &&
    event?.details?.options?.playbookId
  ) {
    return HistoryEventNames.ReprocessDocument;
  }
  return event.name;
};

export const historyEventToIconMap = {
  default: "fad-user-pen",
  [HistoryEventNames.Assigned]: "fad-user",
  [HistoryEventNames.Created]: "fal-file-plus",
  [HistoryEventNames.Transitioned]: "fal-exchange",
  [HistoryEventNames.IssueProcessed]: "fad-file-pen",
  [HistoryEventNames.ApprovalRequested]: "fas-share",
  [HistoryEventNames.ApprovalRequestCanceled]: "far-times-circle",
  [HistoryEventNames.SignatureCreated]: "fad-signature",
  [HistoryEventNames.SignatureUpdated]: "fad-signature",
  [HistoryEventNames.SignatureCanceled]: "fad-signature",
  [HistoryEventNames.VersionAdded]: "fal-file-upload",
  [HistoryEventNames.AccessUpdated]: "fad-lock-keyhole",
  [HistoryEventNames.RenewalUpdated]: "fad-rotate",
  [HistoryEventNames.SiftForReviewQueued]: "fad-arrow-progress",
  [HistoryEventNames.SiftForReviewCreated]: "fad-arrow-progress",
  [HistoryEventNames.SiftForReviewUpdated]: "fad-arrow-progress",
  [HistoryEventNames.SiftForReviewCompleted]: "fad-arrow-progress",
  [HistoryEventNames.SiftForReviewFailed]: "fad-triangle-exclamation",
  [HistoryEventNames.FinalizeDocumentFinished]: "fad-check",
  approvalDenied: "fas-thumbs-down",
  approvalApproved: "fas-thumbs-up",
};

export function getSiftedEvent(events: DocumentHistoryEvent[]) {
  return find(
    events,
    (event) =>
      event.name === HistoryEventNames.SiftForReviewUpdated &&
      event.actionDetails?.status === SiftStatus.Sifted
  );
}

export function wasPreviouslySifted(events: DocumentHistoryEvent[]) {
  return !!getSiftedEvent(events);
}

export const getVersionNumber = (document: Document): number =>
  get(document, "versionNumber");

export const hasMoreVersions = (document: Document): boolean =>
  (getVersionNumber(document) || 1) > 1;

export const getStatus = (document: Document): string =>
  get(document, "status");

export const getAssignee = (document: Document): User =>
  get(document, "assignee");

export const getCreatedBy = (document: Document): User =>
  get(document, "createdBy");

export const getUpdatedBy = (document: DocumentService.Document): User =>
  get(document, "updatedBy");

export const getNotes = (document: DocumentService.Document): string =>
  get(document, "notes");

export const getFinalSignatureDate = (
  document: DocumentService.Document
): number => get(document, "signature.finalSignatureDate");

export const getCounterpartySignerName = (
  document: DocumentService.Document
): string => get(document, "signature.counterpartySignerName");

export const isInitial = (document: Document) =>
  getStatus(document) === INITIAL;

export const isComplete = (document: Document) =>
  getStatus(document) === COMPLETE;

export const isProcessing = (document: Document) =>
  [PROCESSING, REPROCESSING, PENDING].includes(getProcessStatus(document));

export const isProcessed = (document: Document) =>
  !isProcessing(document) && !isFailed(document);

export const isAwaitingApproval = (document: Document) =>
  getStatus(document) === AWAITING_APPROVAL;

export const isReadOnly = (document: Document) =>
  isProcessing(document) || isFailed(document) || !isNegotiating(document);

export const getSourceType = (document: Document) =>
  get(document, "sourceType");

export const isFPPDocument = (document: Document) =>
  getSourceType(document) === SourceType.Internal;

export const isTPPDocument = (document: Document) =>
  getSourceType(document) === SourceType.External;

export const isReviewDocument = (document: Document) =>
  [SourceType.Internal, SourceType.External, SourceType.Review].includes(
    getSourceType(document)
  );

export const isSearchDocument = (document: Document) =>
  getSourceType(document) === SourceType.Search;

export const isInsightsDocument = (document: Document) =>
  getSourceType(document) === SourceType.Insights;

export const isMyDocument = (document: Document, myUserId: number) => {
  return (
    !isNil(myUserId) &&
    (isAssignedTo(document, myUserId) ||
      getUserId(getCreatedBy(document)) === myUserId)
  );
};

export const isAssignedTo = (document: Document, userId: number) =>
  getUserId(getAssignee(document)) === userId;

/**
 * Returns the document file name. Note: we check for both fileName and filename because
 * of differences in the document response model between search and ms-project.
 */
export const getDocumentFileName = (document: Document) =>
  get(document, "file.filename") ||
  get(document, "fileName") ||
  get(document, "filename") ||
  "";

/**
 * Returns the document title, falling back to `title` and `fileName`, if the
 * `documentTitle` property does not exist.
 */
export const getDocumentTitle = (document: Document) =>
  get(document, "documentTitle") ||
  get(document, "title") ||
  getDocumentFileName(document);

export const getRiskLevel = (document: Document) =>
  get(document, "riskLabel") || "";

export const getDocumentId = (
  document: Document | DocumentService.ReviewDocument
) => get(document, "documentId", null);

export const getDocumentCreatedDate = (document: Document) =>
  get(document, "createdDate") || "";

export const getDocumentUpdatedDate = (document: Document) =>
  get(document, "updatedDate") || "";

export const getDocumentVersionDate = (document: Document) =>
  get(document, "versionDate") || "";

export const getRelatedDocumentIds = (document: Document) =>
  get(document, "relatedDocumentIds") || [];

export const hasDocumentRelations = (document: any) =>
  Object.values(getDocumentRelations(document)).some(
    (relation) => !isEmpty(relation)
  );

export const getDocumentRelations = (document: Document): DocumentRelations =>
  get(document, "relations") || {
    related: [],
    amends: [],
    amendedBy: [],
    supersedes: [],
    supersededBy: [],
    terminates: [],
    terminatedBy: [],
    attachments: [],
    attachedTo: null,
  };

export const getRelatedDocuments = (document: Document) =>
  getDocumentRelations(document).related;

export const getDocumentStatus = (document: Document): string =>
  get(document, "status") || "";

export const getPlaybookMetadata = (document: Document) =>
  get(document, "playbookMetadata", []);

export const getPlaybookId = (document: Document) =>
  get(document, "playbookId");

export const getFileInfo = (document: Document): FileInfo =>
  get(document, "file");

export const getDocumentMimeType = (document: Document) => {
  let mimeType = getFileInfo(document)?.mediaType || "";
  mimeType = mimeType.toLowerCase();
  // check if it is type instead of mime-type
  if (mime.types[mimeType]) {
    mimeType = mime.types[mimeType];
  }
  return mimeType;
};

export const getReviewIssues = (document: DocumentService.ReviewDocument) =>
  get(document, "issues.items") || [];

export const getReviewIssue = (
  document: DocumentService.ReviewDocument,
  issueId: string
) => {
  return getReviewIssues(document).find(
    (issue: DocumentService.ReviewClause) => issue.issueId === issueId
  );
};

export const isWithCounterparty = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.WithCounterparty;

export const isInReview = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.InReview;

export const isCanceled = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.Canceled;

export const isAwaitingSignature = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.AwaitingSignature;

export const isExecuted = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.Executed;

export const isFiled = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.Filed;

export const isReadyForSignature = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.ReadyForSignature;

export const isTemplateCreated = (document: Document) =>
  getDocumentStatus(document) === NegotiationStatus.TemplateCreated;

export const isNegotiating = (document: Document) =>
  ![
    NegotiationStatus.ReadyForSignature,
    NegotiationStatus.AwaitingSignature,
    NegotiationStatus.Canceled,
    NegotiationStatus.Executed,
  ].includes(getDocumentStatus(document) as NegotiationStatus);

export const getDocumentProgress = (document: DocumentService.ReviewDocument) =>
  get(document, "extensions.progress");

export const getTotalIssueCount = (document: DocumentService.ReviewDocument) =>
  get(getDocumentProgress(document), "total", 0);

export const getResolvedIssueCount = (
  document: DocumentService.ReviewDocument
) => get(getDocumentProgress(document), "resolved", 0);

export const hasAllIssuesResolved = (
  document: DocumentService.ReviewDocument
) => {
  const totalIssues = getTotalIssueCount(document);
  const resolvedIssues = getResolvedIssueCount(document);
  return totalIssues === resolvedIssues;
};

export const getDocumentProgressPercentage = (
  document: DocumentService.ReviewDocument
): number => {
  if (hasAllIssuesResolved(document)) return 100;
  const resolved = getResolvedIssueCount(document);
  const total = getTotalIssueCount(document);
  if (!total) return 0;
  return (resolved / total) * 100;
};

/**
 * Returns the view type
 * @param mimeType
 */
export const resolveDocumentViewType = (document: Document): ViewType => {
  const mimeType = getDocumentMimeType(document);
  let type = ViewType.HTML;
  if (["PDF", "application/pdf"].includes(mimeType)) {
    type = ViewType.PDF;
  } else if (
    [
      "JPG",
      "image/jpeg",
      "PNG",
      "image/png",
      "GIF",
      "image/gif",
      "TIF",
      "image/tiff",
      "BMP",
      "image/bmp",
    ].includes(mimeType)
  ) {
    type = ViewType.IMAGE;
  }
  return type;
};

/**
 * Format the image mimeType as image/extension
 * @param mimeType
 */
export const resolveImageMimeType = (document: Document) => {
  const mimeType = (getDocumentMimeType(document) || "").toLowerCase();
  return mimeType.includes("image")
    ? mimeType
    : `image/${mimeType !== "jpg" ? mimeType : "jpeg"}`; // image/jpeg is the mime type for JPG images
};

export const getMediaType = (document: DocumentService.Document) =>
  get(document, "file.mediaType") || get(document, "mediaType") || "";

export const getTitle = (document: DocumentService.Document) =>
  get(document, "title") || "";

export const getProcessStatus = (document: DocumentService.Document) =>
  get(document, "processStatus") || "";

export const isFailed = (document: DocumentService.Document) =>
  getProcessStatus(document) === ProcessStatus.Failed;

export const getContractStatus = (
  document: DocumentService.Document
): ContractStatus =>
  get(document, "contractStatus") || ContractStatus.Unspecified;

export function isContractActive(document: DocumentService.Document): boolean {
  return getContractStatus(document) === ContractStatus.Active;
}

export const getRenewalInfo = (
  document: DocumentService.Document
): RenewalInfo => {
  const renewal: any = get(document, "renewal") || {};
  return {
    ...renewal,
    termType: renewal?.termType || TermType.Unsure,
    contractStatus: getContractStatus(document),
    effectiveDate: get(document, "effectiveDate"),
    terminationDate: get(document, "terminationDate"),
  };
};

export const getCounterpartyId = (document: DocumentService.Document): string =>
  get(document, "counterpartyId");

export const getCounterpartyFolderId = (
  document: DocumentService.Document
): string => get(document, "extensions.folderId");

export const getCounterparty = (document: DocumentService.Document): any =>
  get(document, "counterparty");

export const getCounterpartyName = (
  document: DocumentService.Document
): string => getCounterparty(document)?.name;

export const getPlaybookName = (document: DocumentService.Document) =>
  get(document, "playbook.name");

export const getPlaybookType = (document: DocumentService.Document) =>
  get(document, "playbook.type");

export const getParentTypeName = (document: DocumentService.Document) =>
  get(document, "playbook.parentTypeName");

export const getPaperType = (document: DocumentService.Document) =>
  get(document, "playbook.paperType");

export const getVersionControl = (document: DocumentService.Document) =>
  get(document, "versionControl");

export const getSearchHighlighting = (document: DocumentService.Document) =>
  get(document, "highlights.content") || [];

export const getTitleHighlighting = (document: DocumentService.Document) => {
  const highlights = (get(document, "highlights.title") || []).join("...");

  return highlights || getTitle(document);
};

export const getCompanyId = (document: DocumentService.Document) =>
  get(document, "companyId");

export const getRelatedTicketId = (document: DocumentService.Document) =>
  get(document, "relatedTicketId");

export const getEnvelopeId = (document: DocumentService.Document): any =>
  get(document, "signature.envelopeId");

export const isDocuSignProvider = (signatureProvider: IntegrationName) =>
  signatureProvider === IntegrationName.DocuSign;

export const isAdobeSignProvider = (signatureProvider: IntegrationName) =>
  signatureProvider === IntegrationName.AdobeSign;

export const getExternalId = (document: DocumentService.Document): string =>
  get(document, "externalId") || "";

export const getSignatureRecipients = (
  document: DocumentService.Document
): DocumentService.SignatureRecipient[] =>
  get(document, "signature.recipients") || [];

export const getSignedRecipients = (document: DocumentService.Document) => {
  const recipients = getSignatureRecipients(document);
  return recipients.filter((recipient) => recipient.status === "SIGNED");
};

export const isPartiallySignedDocument = (
  document: DocumentService.Document
): boolean =>
  isAwaitingSignature(document) && !isEmpty(getSignedRecipients(document));

export type DocumentRelations = {
  related: DocumentService.Document[];
  amends: DocumentService.Document[];
  amendedBy: DocumentService.Document[];
  supersedes: DocumentService.Document[];
  supersededBy: DocumentService.Document[];
  terminates: DocumentService.Document[];
  terminatedBy: DocumentService.Document[];
  attachments: DocumentService.Document[];
  attachedTo: DocumentService.Document;
};

export enum DocumentRelationName {
  Related = "related",
  Attachment = "attachment",
  AttachedTo = "attachedTo",
  Amends = "amends",
  AmendedBy = "amendedBy",
  Supersedes = "supersedes",
  SupersededBy = "supersededBy",
  Terminates = "terminates",
  TerminatedBy = "terminatedBy",
}

export type DocumentRelation = {
  relatedDocumentId: string;
  relationName: DocumentRelationName;
};

export const isPdf = (document: DocumentService.Document): boolean =>
  PDF.includes(getMediaType(document));

export const isDocx = (document: DocumentService.Document): boolean =>
  DOCX.includes(getMediaType(document));

export const isReviewDocumentMediaType = (document: DocumentService.Document) =>
  isPdf(document) || isDocx(document);

export const getAttachedToDocument = (document: Document) =>
  getDocumentRelations(document).attachedTo;

export const isAttachedToDocument = (document: Document) =>
  !!getAttachedToDocument(document);

export const hasReviewDocumentChanges = (document: Document) =>
  isReviewDocument(document) &&
  isDocx(document) &&
  !isFailed(document) &&
  !isExecuted(document) &&
  !isEqual(
    get(document, "content.original.fileId"),
    get(document, "content.editor.fileId")
  );
