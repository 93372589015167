import { MetadataValue, PlaybookMetadataField } from "@app/entities/metadata";

export type SavedSearchACL = { userId: string; mask: number };

export enum SavedSearchType {
  Document = "DOCUMENT",
  Ticket = "TICKET",
}

export interface SavedSearch {
  name: string;
  id?: string;
  description?: string;
  filters?: string;
  criteria?: string;
  public: boolean;
  search?: string;
  sort?: string;
  share?: SavedSearchACL[];
  createdBy?: number;
  createdDate?: string;
  updatedBy?: number;
  updatedDate?: string;
  displayColumns?: string;
  domainObject?: SavedSearchType;
}

export interface QuickViewDocument {
  abbreviation: string;
  companyId: number;
  content: string | undefined;
  createdBy: { fullName: string; email: string; id: number };
  createdDate: number;
  documentId: number;
  documentTitle: string;
  documentType: string;
  externalDocumentId?: number;
  filename: string;
  metadata?: { [K: string]: MetadataValue };
  mimeType: string;
  negotiationStatus: string;
  owner: { fullName: string; email: string; id: number };
  ownerUserId: number;
  playbookId?: number;
  playbookMetadata: PlaybookMetadataField[];
  projectId: number;
  projectTitle: string;
  riskId: number;
  riskLabel: string;
  sourceType: string;
  status: string;
  updatedBy: { fullName: string; email: string; id: number };
  updatedDate: number;
  versionNumber: number;
}
