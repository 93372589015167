import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import IAction from "@app/types/IAction";
import * as API from "@app/API";
import { get } from "@app/utils/lodash";
import { DataStatus } from "@app/redux/utils";
import { DocumentService } from "@app/types-business/documents";
import { createSelector } from "@reduxjs/toolkit";
import { IPagination } from "@app/types-business/Project";
import { denormalize, normalize } from "normalizr";
import { documentListSchema } from "./schemas";
import { LOAD_QUICKVIEW_DOCUMENT } from "./quick-view";

// #region ACTION TYPES
export const LOAD_DOCUMENTS = "all/documents/load";
export const RESET_DOCUMENTS = "all/documents/reset";
// #endregion

// #region ACTIONS
export const loadDocuments = (query: Record<string, any> = {}): IAction => {
  return {
    type: LOAD_DOCUMENTS,
    promise: API.getDocumentList(query),
  };
};

export const resetDocuments = (): IAction => ({
  type: RESET_DOCUMENTS,
});
// #endregion

// #region SELECTORS
export const documentsState = (state) => state.data.search.documents;

export const getDocumentsMap = createSelector(
  documentsState,
  (state) => get(state, "documents") || {}
);

export const getDocumentIds = createSelector(
  documentsState,
  (state) => get(state, "documentIds") || []
);

export const getDocuments = createSelector(
  getDocumentsMap,
  getDocumentIds,
  (documents, ids) => {
    return denormalize(ids, documentListSchema, { documents });
  }
);

export const getDocumentsStatus = createSelector(
  documentsState,
  (state) => state.status
);

export const areDocumentsLoading = createSelector(
  getDocumentsStatus,
  (status) => status === DataStatus.Loading
);

export const hasDocumentsError = createSelector(
  getDocumentsStatus,
  (status) => status === DataStatus.Error
);

export const hasDocumentsLoaded = createSelector(
  getDocumentsStatus,
  (status) => status === DataStatus.Done
);

export const getPaginationInfo = createSelector(
  documentsState,
  (state) => state.paginationInfo
);

export const getDocumentFilters = createSelector(
  documentsState,
  (state) => state.filters || []
);
// #endregion

//#region REDUCER
export interface DocumentsReduxState {
  status: DataStatus;
  documents: Record<string, DocumentService.ReviewDocument>;
  documentIds: string[];
  filters: DocumentService.DocumentFilter[];
  paginationInfo: IPagination;
}

export const initialState: DocumentsReduxState = {
  status: DataStatus.NotLoaded,
  documents: {},
  documentIds: [],
  filters: [],
  paginationInfo: {
    page: 0,
    totalSize: 0,
    totalPages: 0,
  },
};

export default handleActions(
  {
    [LOAD_DOCUMENTS]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          status: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          status: DataStatus.Error,
        }),
        success: (s) => {
          const {
            documents,
            filters,
            page,
            pageSize,
            totalSize,
            totalPages,
          }: any = get(action, "payload.data") || {};

          const { entities, result } = normalize(
            documents || [],
            documentListSchema
          );
          return {
            ...s,
            documents: entities.documents,
            documentIds: result,
            filters: filters || [],
            paginationInfo: {
              page,
              pageSize,
              totalSize,
              totalPages,
            },
            status: DataStatus.Done,
          };
        },
      });
    },
    [LOAD_QUICKVIEW_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        success: (s) => {
          const document: any = get(action, "payload.data.document") || {};
          return {
            ...s,
            documents: {
              ...s.documents,
              [document.documentId]: {
                ...s.documents[document.documentId],
                objectState: document,
              },
            },
          };
        },
      });
    },
    [RESET_DOCUMENTS]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
