import { Api, ApiResponse } from "./_base";
import moment from "moment-timezone";
import queryString from "query-string";

export * from "./approvals";
export * from "./counterparties";
export * from "./document";
export * from "./playbooks";
export * from "./authentication";
export * from "./chat";
export * from "./insights";
export * from "./companies";
export * from "./model";
export * from "./assembly";
export * from "./assembly-questions";
export * from "./profiles";
export * from "./support";
export * from "./workflows";
export * from "./integrations";
export * from "./config";
export * from "./entities";
export * from "./share";
export * from "./documents";
export * from "./ticket";
export * from "./ml-data";
export * from "./saved-searches";
export * from "./scheduler";
export * from "./alerts";
export * from "./backups";

export function loadAggregationsForField(
  fieldName: string,
  query: Record<string, any> = {},
  entityType = "documents"
): Promise<ApiResponse<any>> {
  query = {
    tz: moment().utcOffset(),
    size: 50,
    ...query,
  };

  return Api.execute({
    url: `/api/${entityType}/aggregations/${fieldName}?${queryString.stringify(
      query
    )}`,
  });
}

export function loadSearchAggregations(data: any): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/documents/search/aggregations`,
    method: "POST",
    data,
  });
}
