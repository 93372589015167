import {
  isNil,
  isEmpty,
  without,
  cloneDeep,
  keys,
  uniq,
} from "@app/utils/lodash";
import queryString from "query-string";

export interface SearchQueryCriterion {
  name: string;
  isNotSetFilter: boolean;
  isExclusionFilter: boolean;
  values: string[];
}

class SearchQuery {
  public static criteriaFilterDelimiter: string = ";";
  public static criteriaKeyValueDelimiter: string = ":";
  public static criteriaValueDelimiter: string = ",";
  public static criteriaValueRangeDelimiter: string = "~";
  public static criteriaNegationCharacter: string = "!";
  public static criteriaNotSetValue = "not-set";

  protected query: Record<string, number | string | string[]>;
  protected criteriaKey: string;
  protected criteria: Record<string, SearchQueryCriterion>;

  constructor(
    query: Record<string, number | string | string[]> = {},
    options = { criteriaKey: "criteria" }
  ) {
    this.criteriaKey = options.criteriaKey;
    this.query = query;

    const criteriaValue = this.query[this.criteriaKey] as string;
    this.criteria = SearchQuery.parseCriteria(criteriaValue || "");
  }

  public static parseCriteria = (criteriaString: string) => {
    const criteria = {};
    if (!criteriaString) {
      return criteria;
    }
    criteriaString
      .split(SearchQuery.criteriaFilterDelimiter)
      .forEach((criterionString) => {
        const parsedCriterion = SearchQuery.parseCriterion(criterionString);
        if (parsedCriterion) {
          criteria[parsedCriterion.name] = parsedCriterion;
        }
      });

    return criteria;
  };

  public static parseCriterion = (
    criterionString: string
  ): SearchQueryCriterion => {
    const [name, value] = criterionString.split(
      SearchQuery.criteriaKeyValueDelimiter
    );

    if (!name || isEmpty(name.trim())) return null;

    const isNegationFilter = name.startsWith(
      SearchQuery.criteriaNegationCharacter
    );
    const isNotSetFilter = isNegationFilter && isNil(value);
    const isExclusionFilter = isNegationFilter && !isNil(value);

    if (isEmpty(value) && !isNotSetFilter) return null;

    const criterion = {
      name: name.replace(/^!/, ""), // remove the bang (!) indicating a null filter value from the name, since internally we'll treat this as having the 'not-set' value
      values: isNotSetFilter
        ? []
        : value
            .split(SearchQuery.criteriaValueDelimiter)
            .map((v) => decodeURIComponent(v)),
      isNotSetFilter,
      isExclusionFilter,
    };

    return criterion;
  };

  public getCriteria(): Record<string, SearchQueryCriterion> {
    return this.criteria;
  }

  public getCriterion(name: string): SearchQueryCriterion | null {
    return this.criteria[name] || null;
  }

  public getValues(key: string): string[] {
    const criterion = this.getCriterion(key);
    if (!criterion) return null;
    return criterion.values;
  }

  public hasCriteria(): boolean {
    return !isEmpty(this.criteria);
  }

  public hasCriterion(key: string): boolean {
    return !!this.getCriterion(key);
  }

  public hasValue(key: string, value: string): boolean {
    if (!key || !value) return false;
    const values = this.getValues(key);
    return Array.isArray(values) && values.includes(value as string);
  }

  public hasValues(key: string): boolean {
    return !isEmpty(this.getValues(key));
  }

  public addValue(
    key: string,
    value: string[],
    isExclusionFilter: boolean = false,
    isNotSetFilter: boolean = false
  ) {
    if (!key || !value) return;

    const values = !this.hasValues(key)
      ? value
      : uniq([...this.getValues(key), ...value]);

    this.setCriterion(key, {
      name: key,
      values,
      isExclusionFilter,
      isNotSetFilter,
    });
  }

  public removeValue(key: string, value: string) {
    if (!key || isEmpty(value) || !this.hasValue(key, value)) return;
    const criterion = this.getCriterion(key);
    const values = criterion?.values || [];
    if (values.includes(value)) {
      const newValue = without(values, value);
      if (isEmpty(newValue)) return this.removeCriterion(key);
      this.setCriterion(key, {
        ...criterion,
        values: newValue,
      });
    }
  }

  public setCriterion(
    key: string,
    criterion: {
      name?: string;
      values: string[];
      isExclusionFilter?: boolean;
      isNotSetFilter?: boolean;
    }
  ) {
    if (!key) return;

    this.criteria[key] = {
      name: criterion?.name || key,
      values: criterion.values,
      isExclusionFilter: !!criterion.isExclusionFilter,
      isNotSetFilter: !!criterion.isNotSetFilter,
    };
  }

  public removeCriterion(key: string) {
    const filters = this.getCriteria();
    delete filters[key];
  }

  public convertCriterionToString(key: string): string {
    const { name, isNotSetFilter, isExclusionFilter, values } =
      this.getCriterion(key);
    const valuesEncoded = values.map((v) => encodeURIComponent(v));
    const criterionString = valuesEncoded.join(
      SearchQuery.criteriaValueDelimiter
    );
    const criterionName =
      isNotSetFilter || isExclusionFilter ? `!${name}` : name;

    return isNotSetFilter
      ? `${criterionName}`
      : `${criterionName}${SearchQuery.criteriaKeyValueDelimiter}${criterionString}`;
  }

  public convertCriteriaToString(): string {
    const criteriaEntries = [];
    const criteria = this.getCriteria();
    if (isEmpty(criteria)) return "";

    keys(criteria).forEach((key) => {
      const criterionString = this.convertCriterionToString(key);
      criteriaEntries.push(criterionString);
    });
    return criteriaEntries.join(SearchQuery.criteriaFilterDelimiter);
  }

  public merge(
    query: Record<string, number | string | string[]>,
    criteriaKey: string = this.criteriaKey
  ) {
    const { [criteriaKey]: criteria, ...queryWithoutCriteria } = query;

    // for non-criteria parameters, just merge them into the internal query object
    this.query = { ...this.query, ...queryWithoutCriteria };

    // for criteria, merge each criterion including individual updates to values
    const newCriteria = SearchQuery.parseCriteria(query[criteriaKey] as string);
    keys(newCriteria).forEach((key) => {
      if (!this.getCriterion(key)) {
        return this.setCriterion(key, newCriteria[key]);
      }

      const newCriterion = newCriteria[key];
      const values = newCriterion.values;
      values.forEach((value) => {
        if (!this.hasValue(key, value)) {
          this.addValue(key, [value]);
        }
      });
    });
  }

  public toQuery(): Record<string, number | string | string[]> {
    const query = cloneDeep(this.query);
    const filterString = this.convertCriteriaToString();
    if (!filterString) {
      delete query[this.criteriaKey];
    } else {
      query[this.criteriaKey] = filterString;
    }
    return query;
  }

  public toQueryString(): string {
    return queryString.stringify(this.toQuery());
  }
}

export default SearchQuery;
