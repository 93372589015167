import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { NOOP } from "@app/utils/helpers";
import { get } from "@app/utils/lodash";
import { createSelector } from "reselect";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryString } from "@app/utils/hooks";
import { DataStatus } from "@app/redux/utils";
import { base64ToArrayBuffer } from "@app/utils/files";
import { DocumentService } from "@app/types-business/documents";
import { DocumentRelationName } from "@app/entities/document";

type Status = "loading" | "error" | "completed" | "submitting";

/* #region  ACTION TYPES */
export const LOAD_QUICKVIEW_DOCUMENT = "QUICKVIEW::LOAD_DOCUMENT";
export const LOAD_QUICKVIEW_DIFF = "QUICKVIEW::LOAD_DIFF";
export const LOAD_QUICKVIEW_BINARY = "QUICKVIEW::LOAD_BINARY";
export const RESET_QUICKVIEW_STATE = "QUICKVIEW::RESET_STATE";
export const UPDATE_QUICKVIEW_DOCUMENT = "QUICKVIEW::UPDATE_DOCUMENT";
export const RELATE_DOCUMENT = "QUICKVIEW::RELATE_DOCUMENT";
export const REFRESH_QUICKVIEW_DOCUMENT =
  "QUICKVIEW::REFRESH_QUICKVIEW_DOCUMENT";

/* #endregion */

/* #region  ACTIONS  */
/**
 * Loads the quickview data/content for a specific document
 * @param id
 */
export const loadQuickView = (
  id: string,
  query?: { versionNumber: number; search: string },
  onSuccess: (data?: any) => void = NOOP,
  onFailure: () => void = NOOP
) => {
  if (!id) return;
  return {
    type: LOAD_QUICKVIEW_DOCUMENT,
    promise: API.loadQuickView(id, query),
    meta: {
      onSuccess,
      onFailure,
    },
  };
};

/**
 * Load the array of clauses from both the playbook and the insights document for comparison
 * @param documentId
 */
export const loadQuickViewDiff = (documentId: number) => ({
  type: LOAD_QUICKVIEW_DIFF,
  promise: API.loadInsightsDiffView(documentId),
});

export const updateQuickViewDocument =
  (
    documentId: string,
    data: any,
    onSuccess: () => void = NOOP,
    onFailure: () => void = NOOP
  ): any =>
  (dispatch) =>
    dispatch({
      type: UPDATE_QUICKVIEW_DOCUMENT,
      promise: API.updateDocument(documentId, data),
      meta: {
        onSuccess,
        onFailure,
      },
    });

export const addRelatedDocument =
  (
    documentId: string,
    relatedDocumentId: string,
    relationName: DocumentRelationName,
    onSuccess: (data?: any) => void = NOOP,
    onFailure: () => void = NOOP
  ): any =>
  (dispatch) => {
    return dispatch({
      type: RELATE_DOCUMENT,
      promise: API.addRelatedDocument(documentId, [
        {
          relatedDocumentId,
          relationName,
        },
      ]),
      meta: {
        documentId,
        onSuccess,
        onFailure,
      },
    });
  };

export const removeRelatedDocument =
  (
    documentId: string,
    relatedDocumentId: string,
    relationName: DocumentRelationName,
    onSuccess: (data?: any) => void = NOOP,
    onFailure: () => void = NOOP
  ): any =>
  (dispatch) =>
    dispatch({
      type: RELATE_DOCUMENT,
      promise: API.removeRelatedDocument(documentId, [
        {
          relatedDocumentId,
          relationName,
        },
      ]),
      meta: {
        documentId,
        onSuccess,
        onFailure,
      },
    });
/**
 * Loads the quickview data/content for a silent document update
 * @param id
 */
export const refreshDocument = (
  id: string,
  onSuccess: (data?: any) => void = NOOP,
  onFailure: () => void = NOOP,
  data: any = {}
) => ({
  type: REFRESH_QUICKVIEW_DOCUMENT,
  promise: API.loadQuickView(id),
  meta: {
    onSuccess,
    onFailure,
    document: data,
  },
});

export const updateDocumentAccess = (
  documentId: string,
  access: DocumentService.UpdateAccessRequest,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_QUICKVIEW_DOCUMENT,
  promise: API.updateAccess(documentId, access),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const resetQuickViewState = () => ({
  type: RESET_QUICKVIEW_STATE,
});
/* #endregion */

//#region HOOKS
export const useDocumentQuickView = (documentId: string) => {
  const dispatch = useDispatch();
  const [query] = useQueryString();
  const { quick_view_search, versionNumber } = query;

  useEffect(() => {
    dispatch(
      loadQuickView(documentId, { versionNumber, search: quick_view_search })
    );
  }, [documentId, versionNumber, quick_view_search, dispatch]);

  const document = useSelector(getQuickViewInfo);
  const isLoading = useSelector(isQuickViewLoading);
  const hasError = useSelector(hasQuickViewError);
  const hasLoaded = useSelector(hasQuickViewLoaded);

  return [
    document,
    { isLoading, hasError, hasLoaded },
    { loadQuickView, updateQuickViewDocument },
  ];
};
//#endregion

/* #region  SELECTORS */
export const quickViewState = (state) => state.data.search.quickView;

export const getQuickViewInfo = createSelector(
  quickViewState,
  (state) => get(state, "document", {}) || {}
);

export const getQuickViewStatus = createSelector(
  quickViewState,
  (state) => state.documentStatus
);

export const isQuickViewLoading = createSelector(
  getQuickViewStatus,
  (status) => status === "loading"
);

export const isUpdatingQuickViewDocument = createSelector(
  getQuickViewStatus,
  (status) => status === "submitting"
);

export const hasQuickViewError = createSelector(
  getQuickViewStatus,
  (status) => status === "error"
);

export const hasQuickViewLoaded = createSelector(
  getQuickViewStatus,
  (status) => status === "completed"
);

export const getQuickViewHtml = createSelector(
  quickViewState,
  (state) => state.documentHtml || ""
);

export const getDocumentFileName = createSelector(
  getQuickViewInfo,
  (quickViewInfo) => get(quickViewInfo, "filename", null)
);

export const getDocumentMimeType = createSelector(
  getQuickViewInfo,
  (quickViewInfo) => {
    return get(quickViewInfo, "mimeType", null);
  }
);

export const getDocumentMetadata = createSelector(
  getQuickViewInfo,
  (quickViewInfo) => get(quickViewInfo, "metadata", {}) || {}
);

export const getDocumentId = createSelector(getQuickViewInfo, (quickViewInfo) =>
  get(quickViewInfo, "documentId", null)
);

export const getExternalDocumentId = createSelector(
  getQuickViewInfo,
  (quickViewInfo) => get(quickViewInfo, "externalDocumentId", null)
);

export const getUpdatedDate = createSelector(
  getQuickViewInfo,
  (quickViewInfo) => get(quickViewInfo, "updatedDate", null)
);

export const getProjectId = createSelector(getQuickViewInfo, (quickViewInfo) =>
  get(quickViewInfo, "projectId", null)
);

export const getProjectName = createSelector(
  getQuickViewInfo,
  (quickViewInfo) => get(quickViewInfo, "title", null)
);

export const getPlaybookId = createSelector(getQuickViewInfo, (quickViewInfo) =>
  get(quickViewInfo, "playbookId", null)
);

export const getStatus = createSelector(getQuickViewInfo, (quickViewInfo) =>
  get(quickViewInfo, "status", null)
);

export const getBase64Content = createSelector(
  quickViewState,
  (state) => state.binaryBase64
);

export const getBinaryStatus = createSelector(
  quickViewState,
  (state) => state.binaryStatus
);

export const isLoadingBinary = createSelector(
  getBinaryStatus,
  (status) => status === "loading"
);

export const hasBinaryError = createSelector(
  getBinaryStatus,
  (status) => status === "error"
);

export const hasLoadedBinary = createSelector(
  getBinaryStatus,
  (status) => status === "completed"
);

export const getBinaryContent = createSelector(
  getBase64Content,
  (base64Content) => base64ToArrayBuffer(base64Content)
);

export const getDiffClauses = createSelector(
  quickViewState,
  (state) => state.diffClauses || []
);

export const getDiffClausesStatus = createSelector(
  quickViewState,
  (state) => state.diffClausesStatus
);

export const isLoadingDiffClauses = createSelector(
  getDiffClausesStatus,
  (status) => status === "loading"
);

export const hasDiffClausesError = createSelector(
  getDiffClausesStatus,
  (status) => status === "error"
);

export const hasLoadedDiffClauses = createSelector(
  getDiffClausesStatus,
  (status) => status === "completed"
);

// #endregion

export interface QuickViewState {
  document: any;
  documentHtml: string;
  documentStatus: Status;
  binaryBase64: string;
  binaryStatus: Status;
  diffClauses: any[];
  diffClausesStatus: Status;
}

export const initialState: QuickViewState = {
  // information (e.g., content, metadata, etc) about the document
  document: null,
  // quick view html
  documentHtml: null,
  // content status: loading, error, completed
  documentStatus: null,
  // binary content (e.g., PDF) base64 encoded
  binaryBase64: null,
  // binary status: loading, error, completed
  binaryStatus: null,
  // array of clause diff information
  diffClauses: null,
  // diff status: loading, error, completed
  diffClausesStatus: null,
};

const handleUpdateDocument = (state, action) => {
  return handle(state, action, {
    start: (s: QuickViewState): QuickViewState => ({
      ...s,
      documentStatus: "submitting",
    }),
    failure: (s: QuickViewState): QuickViewState => ({
      ...s,
      documentStatus: "completed",
    }),
    success: (s: QuickViewState): QuickViewState => {
      return {
        ...s,
        documentStatus: "completed",
        document: {
          ...s.document,
          ...(get(action, "payload.data") || {}),
        },
      };
    },
  });
};

export default handleActions(
  {
    [LOAD_QUICKVIEW_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        start: (s: QuickViewState): QuickViewState => ({
          ...s,
          documentStatus: "loading",
          binaryBase64: null,
        }),
        failure: (s: QuickViewState): QuickViewState => ({
          ...s,
          documentStatus: "error",
        }),
        success: (s: QuickViewState): QuickViewState => {
          const document = get(action, "payload.data.document") || {};
          const documentHtml = get(action, "payload.data.content.html") || null;
          const binaryBase64 =
            get(action, "payload.data.content.binary") || null;
          return {
            ...s,
            document,
            documentHtml,
            binaryBase64,
            documentStatus: "completed",
          };
        },
      });
    },
    [LOAD_QUICKVIEW_DIFF]: (state, action) => {
      return handle(state, action, {
        start: (s: QuickViewState): QuickViewState => ({
          ...s,
          diffClausesStatus: "loading",
        }),
        failure: (s: QuickViewState): QuickViewState => ({
          ...s,
          diffClausesStatus: "error",
        }),
        success: (s: QuickViewState): QuickViewState => ({
          ...s,
          diffClauses: get(action, "payload.data", null),
          diffClausesStatus: DataStatus.Done,
        }),
      });
    },
    [UPDATE_QUICKVIEW_DOCUMENT]: handleUpdateDocument,
    [RELATE_DOCUMENT]: handleUpdateDocument,
    // a "silent" (no status change) update of the entire quickview to include any updated related docs
    [REFRESH_QUICKVIEW_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        success: (s: QuickViewState): QuickViewState => {
          const meta = get(action, "meta.document") || {};
          const document = {
            ...(get(action, "payload.data.document") || {}),
            ...meta,
          };
          return {
            ...s,
            document,
          };
        },
      });
    },
    [RESET_QUICKVIEW_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState
);
