import {
  IContractClauseRequest,
  IClauseInstance,
  IFallbackCreate,
} from "../types-business/Playbook";
import constants from "../utils/constants";
import { IUploadRequest } from "../types-business/Project";
import { MetadataField, PlaybookMetadataField } from "@app/entities/metadata";
import { PlaybookClause } from "@app/entities/playbook/playbook-clause";
import { CreatePlaybookMetadataRequest } from "../types-business/Insights";
import queryString from "query-string";
import { Api, ApiResponse } from "./_base";
import moment from "moment-timezone";
import { loadSearchAggregations } from ".";

/**
 * Creates a new playbook/contract type.
 * @param playbookName
 */
export function createPlaybook(
  createPlaybookData: any,
  companyId
): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks?companyId=${companyId}`,
    data: createPlaybookData,
    method: "POST",
  });
}

/**
 * Deletes an existing playbook
 * @param playbookId
 */
export function deletePlaybook(playbookId: number): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/${playbookId}`,
    method: "DELETE",
  });
}

/**
 * Returns the list of playbooks associated with a given company ID
 */
export function getPlaybooks(companyId?: number): Promise<ApiResponse<any>> {
  const query = {
    offset: moment().utcOffset(),
    companyId,
    size: 500,
  };
  return Api.execute({
    url: `/api/proxy/ms-project/v3/playbooks?${queryString.stringify(query)}`,
  });
}

// !! Restricted to users with Playbook List scope
export function getPlaybookList(companyId?: number): Promise<ApiResponse<any>> {
  const params = {
    offset: moment().utcOffset(),
    companyId,
    size: 500,
  };
  return Api.execute({
    url: "/api/playbooks",
    params,
  });
}

// Restricted to users with ReviewAssemble scope
export function getAssemblyPlaybooks(
  companyId?: number
): Promise<ApiResponse<any>> {
  const query = {
    offset: moment().utcOffset(),
    companyId,
  };
  return Api.execute({
    url: `/api/playbooks/assembly?${queryString.stringify(query)}`,
  });
}

export function getPlaybook(
  playbookId: number,
  includeClauses: boolean = true
): Promise<any> {
  return Api.execute({
    url: `/api/playbooks/${playbookId}${
      includeClauses
        ? "?expandFields=clauses,clauses.issues,clauses.issues.fallbacks,clauses.structureSettings"
        : ""
    }`,
  });
}

/**
 * Publishes a playbook, which changes its status from draft to published
 * @param playbookId
 */
export function publishPlaybook(playbookId): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/${playbookId}/publish`,
    data: { playbookId },
    method: "POST",
  });
}

/**
 * Creates a new clause for a given playbook
 * @param playbookId
 * @param clause
 */
export function createPlaybookClause(
  playbookId: number,
  clause: IContractClauseRequest
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/${playbookId}/clauses`,
    data: clause,
    method: "POST",
  });
}

/**
 * Deletes a given clause from the Playbook
 * @param clauseId
 */
export function deleteClause(clauseId: number): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-clauses/${clauseId}`,
    method: "DELETE",
  });
}

/**
 * Updates a fallback
 * @param fallbackId
 * @param update Object with the fields that are to be updated/patched
 */
export function updateFallback(
  fallbackId: number,
  update: any = {}
): Promise<ApiResponse<IClauseInstance>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-fallbacks/${fallbackId}`,
    data: update,
    method: "PATCH",
  });
}

export function updateClause(
  clauseId: number,
  update: any
): Promise<ApiResponse<PlaybookClause>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-clauses/${clauseId}`,
    data: update,
    method: "PATCH",
  });
}

/**
 * Deletes a fallback
 * @param fallbackId The id of the fallback to be deleted
 */
export function deleteFallback(fallbackId: number): Promise<ApiResponse<void>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-fallbacks/${fallbackId}`,
    method: "DELETE",
  });
}

export function createFallback(
  issueId: number,
  fallback: IFallbackCreate
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-issues/${issueId}/fallbacks`,
    data: fallback,
    method: "POST",
  });
}

export function updatePlaybook(
  playbookId: number,
  data: any = {}
): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/${playbookId}`,
    method: "PATCH",
    data,
  });
}

/**
 * Export playbook
 * @param playbookId
 */
export function exportPlaybook(playbookId: number, data: any): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/export/${playbookId}/docx`,
    data,
    method: "POST",
  });
}

/**
 * Create Issue
 * @param clauseId
 * @param data
 */
export function createIssue(clauseId: number, data: any): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-clauses/${clauseId}/issues`,
    data,
    method: "POST",
  });
}

/**
 * Update Issue
 * @param issueId
 * @param data
 */
export function updateIssue(issueId: number, data: any): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-issues/${issueId}`,
    data,
    method: "PATCH",
  });
}

/**
 * Delete Issue
 * @param issueId
 */
export function deleteIssue(issueId: number): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-issues/${issueId}`,
    method: "DELETE",
  });
}

export function loadPlaybookClause(
  clauseId: number,
  expandFields: boolean = true
): Promise<any> {
  return Api.execute({
    url: `${
      constants.BASE_URL
    }/api/proxy/ms-project/v3/playbook-clauses/${clauseId}${
      expandFields
        ? "?expandFields=clauses.issues,clauses.issues.fallbacks"
        : ""
    }`,
  });
}

/**
 * Get playbook search results
 * @param playbookId
 * @param query
 */
export function loadSearchResults(playbookId: number, query: string) {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/insights-client/v3/playbooks/playbook/${playbookId}?${query}`,
  });
}

/**
 * Download the default playbook template
 */
export function downloadDefaultTemplate(): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/default-template`,
  });
}

/**
 * Download the custom playbook template
 * @param playbookId
 */
export function downloadTemplate(playbookId: number): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/${playbookId}/template`,
  });
}

/**
 * Upload the playbook template
 * @param playbookId
 * @param request
 */
export function uploadPlaybookTemplate(
  playbookId: number,
  request: IUploadRequest
): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/${playbookId}/template`,
    data: request,
    method: "PUT",
  });
}

/**
 * Loads the history information for a given playbook
 * @param playbookId
 */
export function getPlaybookHistory(
  playbookId: number,
  query: string
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbooks/${playbookId}/history?${query}`,
  });
}

export function getPlaybookStats(companyId: number): Promise<ApiResponse<any>> {
  const payload = {
    companyId: companyId,
    size: 1000,
    tz: moment().utcOffset(),
    criteria: "document.sourceType:INS",
    objectType: ["document"],
    aggregations: [
      {
        name: "playbook.playbookId",
        keyword: {
          aggregations: [
            {
              name: "document.processStatus",
            },
          ],
        },
      },
    ],
  };

  return loadSearchAggregations(payload);
}

/**
 * Load Company Metadata
 * @param companyId: number
 */
export function loadCompanyMetadata(companyId: number): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/metadata/company/${companyId}`,
  });
}

export function createMetadataEntry(
  companyId: number,
  metadataEntry: MetadataField
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/metadata?companyId=${companyId}`,
    data: metadataEntry,
    method: "POST",
  });
}

export function updateMetadataEntry(
  metadataId: number,
  update: any
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/metadata/${metadataId}`,
    data: update,
    method: "PATCH",
  });
}

export function deleteMetadataEntry(
  metadataId: number
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/metadata/${metadataId}`,
    method: "DELETE",
  });
}

export function moveMetadataEntry(
  companyId: number,
  metadataId: number,
  position: number
): Promise<ApiResponse<any>> {
  return new Promise((resolve, reject) => {
    updateMetadataEntry(metadataId, { position })
      .then(() => resolve(loadCompanyMetadata(companyId)))
      .catch((e) => reject(e));
  });
}

/**
 * Load Playbook Metadata
 * @param playbookId: number
 * @param companyId: number (to fallback to playbook defaults)
 */
export function loadPlaybookMetadata(
  playbookId: number,
  companyId?: number
): Promise<any> {
  const query: any = {};
  query.companyId = companyId || undefined;
  return Api.execute({
    url: `/api/metadata/playbook/${playbookId}?${queryString.stringify(query)}`,
  });
}

/**
 * Add playbook metadata
 * @param playbookId
 * @param metadata
 */
export function addPlaybookMetadata(
  playbookId: number,
  metadataId: number,
  request: CreatePlaybookMetadataRequest
): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/metadata/playbook/${playbookId}/metadata/${metadataId}`,
    data: request,
    method: "POST",
  });
}

/**
 * Update playbook metadata
 * @param metadata
 */
export function updatePlaybookMetadata(
  metadataId: number,
  update: Partial<PlaybookMetadataField>
): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/metadata/playbook-metadata/${metadataId}`,
    data: update,
    method: "PATCH",
  });
}

/**
 * Delete playbook metadata
 * @param metadataId
 */
export function deletePlaybookMetadata(metadataId: number): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-metadata/${metadataId}`,
    method: "DELETE",
  });
}

export function loadContractTypes(): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v1/contract-types`,
  });
}

export function getPlaybookClauseSchemas(): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/schemas/playbook-clauses`,
  });
}

export function getPlaybookSchema(): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/api-settings/v1/schemas/PLAYBOOK`,
  });
}

/**
 * Returns the list of playbooks associated with a given metadata ID
 */
export function getMetadataPlaybooks(
  metadataId?: number
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/proxy/ms-project/v3/playbook-metadata/${metadataId}/list`,
  });
}
