import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { get, keyBy, filter } from "@app/utils/lodash";
import * as API from "@app/API";
import { NOOP, resolveCompanyId } from "@app/utils/helpers";
import { MetadataField } from "@app/entities/metadata";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import { useEffect, useMemo } from "react";

//#region TYPES
export const CREATE_METADATA_ENTRY = "ADMINISTRATION::CREATE_METADATA_ENTRY";
export const UPDATE_METADATA_ENTRY = "ADMINISTRATION::UPDATE_METADATA_ENTRY";
export const DELETE_METADATA_ENTRY = "ADMINISTRATION::DELETE_METADATA_ENTRY";
export const LOAD_METADATA = "ADMINISTRATION::LOAD_METADATA";
export const MOVE_METADATA_ENTRY = "ADMINISTRATION::MOVE_METADATA_ENTRY";
export const RESET_COMPANY_METADATA = "ADMINISTRATION::RESET_COMPANY_METADATA";

// todo: reset administration state no longer resets this - make sure to call anywhere reset admin is called
//#endregion

// #region ACTIONS

export const loadCompanyMetadata =
  (companyId?: number): any =>
  (dispatch, getState) => {
    companyId = companyId || resolveCompanyId(getState());
    return dispatch({
      type: LOAD_METADATA,
      promise: API.loadCompanyMetadata(companyId),
      meta: {
        companyId,
      },
    });
  };

export const resetCompanyMetadata = () => {
  return {
    type: RESET_COMPANY_METADATA,
  };
};

export const createMetadataEntry =
  (
    metadataEntry: MetadataField,
    onSuccess: () => void = NOOP,
    onFailure: () => void = NOOP
  ) =>
  (dispatch, getState) => {
    const companyId = resolveCompanyId(getState());
    return dispatch({
      type: CREATE_METADATA_ENTRY,
      promise: API.createMetadataEntry(companyId, metadataEntry),
      meta: {
        onSuccess,
        onFailure,
      },
    });
  };

export const updateMetadataEntry = (
  metadataId: number,
  update: any,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
): IAction => ({
  type: UPDATE_METADATA_ENTRY,
  promise: API.updateMetadataEntry(metadataId, update),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const deleteMetadataEntry = (
  metadataId: number,
  onSuccess = NOOP,
  onFailure = NOOP
): IAction => ({
  type: DELETE_METADATA_ENTRY,
  promise: API.deleteMetadataEntry(metadataId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const moveMetadataEntry =
  (
    metadataId: number,
    position: number,
    onSuccess: () => void = NOOP,
    onFailure: () => void = NOOP
  ): any =>
  (dispatch, getState) => {
    const companyId = resolveCompanyId(getState());
    return dispatch({
      type: MOVE_METADATA_ENTRY,
      promise: API.moveMetadataEntry(companyId, metadataId, position),
      meta: {
        onSuccess,
        onFailure,
      },
    });
  };
//#endregion

//#region SELECTORS

export const companyMetadataState = (state) => state.data.metadata.company;

export const getMetadataList = createSelector(companyMetadataState, (state) =>
  get(state, "metadataList", [])
);

export const getMetadataStatus = createSelector(
  companyMetadataState,
  (state) => state.metadataStatus
);

export const isMetadataLoading = createSelector(
  getMetadataStatus,
  (status) => status === DataStatus.Loading
);

export const isMetadataSubmitting = createSelector(
  getMetadataStatus,
  (status) => status === DataStatus.Submitting
);

export const isMetadataDeleting = createSelector(
  getMetadataStatus,
  (status) => status === "deleting"
);

export const hasMetadataError = createSelector(
  getMetadataStatus,
  (status) => status === DataStatus.Error
);

export const hasMetadataLoaded = createSelector(
  getMetadataStatus,
  (status) => status === DataStatus.Done
);

export const getActiveCompanyId = createSelector(
  companyMetadataState,
  (state) => state.key
);

export const getCompanyMetadataMap = createSelector(
  getMetadataList,
  (metadata) => keyBy(metadata, "id")
);

//#endregion

//#region HOOKS
export const useCompanyMetadata = () => {
  const dispatch = useDispatch();

  const metadata = useSelector(getMetadataList);
  const isLoading = useSelector(isMetadataLoading);
  const hasError = useSelector(hasMetadataError);
  const hasLoaded = useSelector(hasMetadataLoaded);
  const isSubmitting = useSelector(isMetadataSubmitting);
  const isDeleting = useSelector(isMetadataDeleting);

  const companyId = resolveCompanyId();

  useEffect(() => {
    const status = runSelector(getMetadataStatus);
    const key = runSelector(getActiveCompanyId);
    if (isDataNotLoaded(status) || (!!key && key !== companyId)) {
      dispatch(loadCompanyMetadata(companyId));
    }
  }, [companyId, dispatch]);

  const status = useMemo(
    () => ({ isLoading, hasError, hasLoaded, isSubmitting, isDeleting }),
    [isLoading, hasError, hasLoaded, isSubmitting, isDeleting]
  );

  const actions = useMemo(
    () => ({
      createMetadataEntry,
      deleteMetadataEntry,
      loadCompanyMetadata,
      updateMetadataEntry,
      moveMetadataEntry,
    }),
    []
  );

  return useMemo(
    () => [metadata, status, actions],
    [metadata, status, actions]
  );
};

export function useNonSystemFields() {
  const [companyMetadata = [], companyMetadataStatus] = useCompanyMetadata();
  return useMemo(() => {
    const fields = companyMetadata.filter((field) => field.system === false);
    return [fields, companyMetadataStatus];
  }, [companyMetadata, companyMetadataStatus]);
}
//#endregion

//#region REDUCER
export interface MetadataReduxState {
  metadataStatus: DataStatus;
  metadataList: any[];
  key: number;
}

export const initialState: MetadataReduxState = {
  metadataStatus: DataStatus.NotLoaded,
  metadataList: [],
  // the resource id of the current company (companyId)
  key: null,
};

const updateMetadataEntryState = (state, action) => {
  const metadata = get(action, "payload.data", {});
  const metadataId = get(action, "payload.data.id", 0);
  return (state.metadataList || []).map((m) =>
    m.id === metadataId ? { ...metadataId, ...metadata } : m
  );
};

const handleMetadataUpdate = (state, action) => {
  return handle(state, action, {
    start: (s) => ({
      ...s,
      metadataStatus: DataStatus.Submitting,
    }),
    failure: (s) => ({
      ...s,
      metadataStatus: DataStatus.Done,
    }),
    success: (s) => {
      return {
        ...s,
        metadataList: updateMetadataEntryState(state, action),
        metadataStatus: DataStatus.Done,
      };
    },
  });
};

export default handleActions(
  {
    [LOAD_METADATA]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          metadataStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          metadataStatus: DataStatus.Error,
        }),
        success: (s) => {
          return {
            ...s,
            key: get(action, "meta.companyId", null),
            metadataList: get(action, "payload.data", []),
            metadataStatus: DataStatus.Done,
          };
        },
      });
    },
    [CREATE_METADATA_ENTRY]: handleMetadataUpdate,
    [UPDATE_METADATA_ENTRY]: handleMetadataUpdate,
    [DELETE_METADATA_ENTRY]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          metadataStatus: "deleting",
        }),
        failure: (s) => ({
          ...s,
          metadataStatus: DataStatus.Error,
        }),
        success: (s) => {
          const metadataList = filter(
            state.metadataList || [],
            (m) => m.id !== get(action, "meta.metadataId", null)
          );
          return {
            ...s,
            metadataList,
            metadataStatus: DataStatus.Done,
            editMetadataId: null,
          };
        },
      });
    },
    [MOVE_METADATA_ENTRY]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          metadataStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          metadataStatus: DataStatus.Error,
        }),
        success: (s) => {
          return {
            ...s,
            metadataList: get(action, "payload.data", []),
            metadataStatus: DataStatus.Done,
          };
        },
      });
    },
    [RESET_COMPANY_METADATA]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
